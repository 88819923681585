<template>
  <auth-layout>
    <page-section
      class="
        mx-auto
        max-w-lg
        flex-1 flex flex-col
        items-center
        px-4
      "
    >
      <loader v-if="loading" />
      <section v-else class="text-center">
        <page-header>
          <page-title>
            Confirmare solicitare
            <span class="text-yellow-600">#{{ id }} </span>
          </page-title>
        </page-header>
        <v-card class="p-20 mb-4">
          <h2 class="mb-4 text-xl font-medium">Status solicitare</h2>
          <h4 class="mb-4 uppercase text-md text-yellow-600 font-medium">
            {{ status }}
          </h4>
          <p class="mb-4 font-bold">Va multumim!</p>
        </v-card>
        <router-link
          to="/"
          class="
            text-md text-gray-700
            hover:text-gray-900
            border-b border-transparent
            hover:border-gray-900 hover:border-current
            capitalize
          "
        >
          Inapoi
          <span class="font-bold">acasa</span>
        </router-link>
      </section>
    </page-section>
  </auth-layout>
</template>

<script>
import VCard from "../components/VCard.vue";
import PageHeader from "../components/PageHeader.vue";
import AuthLayout from "../layout/AuthLayout.vue";
import PageTitle from "../components/PageTitle.vue";
import Loader from "../components/Loader.vue";
import useLoader from "../composables/useLoader";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";
import rfqsApi from "../api/resources/rfqsApi";
import PageSection from "../components/PageSection.vue";

export default {
  components: { VCard, PageHeader, AuthLayout, PageTitle, Loader, PageSection },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { loading, endLoading } = useLoader(true);
    const id = ref("");
    const status = ref("");

    rfqsApi
      .confirmation(route.query)
      .then((response) => {
        id.value = response.data.id;
        status.value = response.data.status.name;
        endLoading();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          router.push({ name: "notFound" });
        }
      });

    return {
      id,
      status,
      loading,
    };
  },
};
</script>

<style></style>
