<template>
  <section class="bg-white shadow rounded p-10">
    <slot></slot>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
